import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import InfoNode from '../../component/InfoNode';
import Container from '../../component/Container';
import { Wrapper, InfoItems, Map, TitleMap } from './styled';

export default function PayInfo() {
  const {
    allStrapiPayBlock: { nodes },
  } = useStaticQuery(graphql`
    query PayInfoQuery {
      allStrapiPayBlock(sort: { fields: priority }) {
        nodes {
          id
          description
          header
          priority
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 160, height: 160, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Wrapper>
        <InfoItems>
          {nodes.map(({ id, header, description, image: { localFile } }) => (
            <InfoNode
              key={id}
              icon={localFile}
              header={header}
              description={description}
            />
          ))}
        </InfoItems>
      </Wrapper>
    </Container>
  );
}
