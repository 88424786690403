import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../component/layout';
import Seo from '../component/seo';
import PayInfo from '../sections/PayInfo';

export default function Pay() {
  const {
    strapiMetaPage: { header, description },
  } = useStaticQuery(
    graphql`
      query Pay {
        strapiMetaPage(page_name: { eq: "pay" }) {
          header
          description
          page_name
        }
      }
    `,
  );

  return (
    <Layout titleHeader="Доставка і оплата" activePath="/pay">
      <Seo title={header} description={description || ''} />
      <PayInfo />
    </Layout>
  );
}
