import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 560px;
  max-height: 300px;
  padding: 0 25px;
  text-align: center;
  margin-bottom: 100px;

  @media (max-width: 1200px) {
    width: 460px;
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Icon = styled.div`
  width: 160px;
  height: 160px;
  margin-bottom: 30px;
`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
  line-height: 1.35;
`;

export const Description = styled.div`
  line-height: 1.55;
  color: #000;
  font-size: 14px;
  font-weight: 700;
`;
