import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  margin-bottom: 50px;
`;

export const InfoItems = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 100px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Map = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TitleMap = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
  line-height: 1.35;

  @media (max-width: 576px) {
    font-size: 20px;
  }
`;
