import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Wrapper, Icon, Header, Description } from './styled';

export default function InfoNode({ icon, header, description }) {
  return (
    <Wrapper>
      <Icon>
        <GatsbyImage image={getImage(icon)} alt={header} />
      </Icon>
      {header && <Header>{header}</Header>}
      <Description>{description}</Description>
    </Wrapper>
  );
}

InfoNode.defaultProps = {
  header: null,
};

InfoNode.propTypes = {
  icon: PropTypes.string.isRequired,
  header: PropTypes.string,
  description: PropTypes.string.isRequired,
};
